exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-sobre-nos-tsx": () => import("./../../../src/pages/sobre-nos.tsx" /* webpackChunkName: "component---src-pages-sobre-nos-tsx" */),
  "component---src-pages-sucesso-tsx": () => import("./../../../src/pages/sucesso.tsx" /* webpackChunkName: "component---src-pages-sucesso-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/single/Page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */),
  "component---src-templates-single-post-tsx": () => import("./../../../src/templates/single/Post.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */),
  "component---src-templates-single-service-tsx": () => import("./../../../src/templates/single/Service.tsx" /* webpackChunkName: "component---src-templates-single-service-tsx" */)
}

